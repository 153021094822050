<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <div class="mb-2">
                    <mdb-btn color="secondary"
                             @click="add_store()">
                        <i class="fa fa-plus mr-2"></i>
                        &nbsp;新增分店
                    </mdb-btn>
                </div>
                <table class="table table-striped rwd-table-dispersion">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th style="width:15%">分店名稱</th>
                            <th style="width:15%">電話</th>
                            <th style="width:30%">地址</th>
                            <th class="text-center"
                                style="width:5em">啟用</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in storeList"
                            :key="`store_${sk}`">
                            <td data-title="#">{{sk+1}}</td>
                            <td data-title="分店名稱"
                                @keypress.enter="save_store(s)">
                                <span v-show="!s.edit">{{s.name}}</span>
                                <mdb-input type="text"
                                           v-show="s.edit"
                                           v-model="s.name"
                                           class="my-0"
                                           placeholder="請輸入分店名稱" />
                            </td>
                            <td data-title="電話"
                                @keypress.enter="save_store(s)">
                                <span v-show="!s.edit">{{s.phone}}</span>
                                <mdb-input type="text"
                                           v-show="s.edit"
                                           v-model="s.phone"
                                           class="my-0"
                                           placeholder="請輸入電話" />
                            </td>
                            <td data-title="地址"
                                @keypress.enter="save_store(s)">
                                <div v-show="!s.edit">{{s.address}}</div>
                                <mdb-input type="text"
                                           v-show="s.edit"
                                           v-model="s.address"
                                           class="my-0"
                                           placeholder="請輸入地址" />
                            </td>
                            <td data-title="啟用"
                                class="text-left text-md-center">
                                <span v-show="!s.edit">
                                    <i class="fa"
                                       :class="s.active?'fa-check':'fa-times'"></i>
                                </span>
                                <mdb-switch offLabel=""
                                            onLabel=''
                                            :checked="s.active"
                                            v-show="s.edit"
                                            @getValue="val=>s.active=val" />
                            </td>
                            <td>
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="s.edit=true"
                                         v-show="!s.edit">
                                    <i class="fa fa-edit mr-2"></i>
                                    編輯
                                </mdb-btn>
                                <mdb-btn color="default"
                                         size="sm"
                                         @click="save_store(s)"
                                         v-show="s.edit">
                                    <i class="fa fa-save mr-2"></i>
                                    儲存
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"
                                v-show="storeList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import { mdbCard, mdbCardBody, mdbInput, mdbBtn, mdbSwitch } from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbSwitch,
  },
  data() {
    return {
      storeList: [],
      storeEmpty: {},
    };
  },
  mounted() {
    let vue = this,
      query = [
        {
          name: "store",
          data: {},
          sort: {
            active: 1,
            name: 0,
          },
        },
        {
          name: "store",
        },
      ];
    vue.$store
      .dispatch("get_form", {
        payload: {
          url: `data/get_data/?query=${JSON.stringify(query)}`,
        },
      })
      .then((res) => {
        if (res.data.status == "ok") {
          // console.info(res.data);
          vue.storeList = res.data.storeList.map((item) => {
            item.edit = false;
            item.ajaxing = false;
            item.active = item.active == "1";
            return item;
          });
          res.data.storeEmpty.edit = true;
          res.data.storeEmpty.ajaxing = false;
          res.data.storeEmpty.active = true;
          res.data.storeEmpty.id = "";
          vue.storeEmpty = res.data.storeEmpty;
        }
      });
  },
  methods: {
    add_store() {
      this.storeList.unshift(Object.assign({}, this.storeEmpty));
    },
    save_store(s) {
      let vue = this;
      if (!s.ajaxing && s.edit) {
        s.ajaxing = true;
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "data/store_save",
              data: { data: JSON.stringify(s) },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              s.id = res.data.store_id;
              s.edit = false;
              s.ajaxing = false;
            }
          });
      }
    },
  },
};
</script>